@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/** DIALOG BACKDROP COLOR **/
.bg-bl-grey-500-opacity-75 {
  background-color: rgba(107, 114, 128, 0.75);
}

/** NAV & SUB-MENU **/
.sidenav-wrapper.side-nav-expanded {
  min-width: 240px;
  max-width: 242px;
}

.dark .sidenav-wrapper {
  background-color: var(--bl-grey-800) !important;
}

.nav-menu-items {
  @apply flex flex-row items-center text-lg antialiased font-medium cursor-pointer no-underline;
}

.sub-menu-items-desktop {
  @apply flex flex-col no-underline text-center items-center justify-center;
}

.mat-drawer-container {
  background-color: var(--bl-grey-100);
}

.dark .mat-drawer-container {
  background-color: var(--bl-grey-800);
}

.mat-drawer-content {
  overflow: unset;
}

.material-icons {
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.mat-mdc-header-cell:first-child {
  padding: 0 !important;
}

.mat-mdc-cell:first-child {
  padding: 0 !important;
}

.cdk-overlay-pane.fixed-height-scrollfix .mat-mdc-dialog-container {
  overflow: unset;
  display: flex;
  flex-direction: column;
}
.cdk-overlay-pane.fixed-height-scrollfix .mat-mdc-dialog-container ng-component {
  display: flex;
  flex-direction: column;
  min-height: 1px;
}

.employee-dialog .mat-mdc-dialog-container {
  padding-top: 0px;
}

.user-invitation-dialog .mat-mdc-dialog-container {
  background-color: var(--bl-grey-50);
}

.dark .user-invitation-dialog .mat-mdc-dialog-container {
  background-color: var(--bl-grey-800);
}

.dark .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--bl-grey-800) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 14px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-weight: 400 !important;
}

.mdc-dialog__title::before {
  height: 0 !important;
}

.mdc-dialog__actions {
  padding: 24px !important;
}

.discount-expired-dialog {
  width: 100%;
  background-color: var(--bl-grey-800);
}

@media (min-width: 769px) {
  .discount-expired-dialog {
    width: 84rem;
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 0;
    margin: 0;
  }
}

.mat-content {
  margin-right: 10px;
  display: unset !important;
  flex: unset !important;
}

.mat-primary .mat-mdc-option {
  background-color: white;
}

.mat-mdc-table {
  margin-right: theme('margin.4');
  margin-left: theme('margin.4');
}

.mat-mdc-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.flip-horizontally {
  transform: scaleX(-1);
}

.backdrop-dark {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-arrow {
  margin: 0 4px;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

/* Dark mode styling attestand table */
.dark .mat-mdc-select-arrow {
  color: var(--bl-grey-400);
}
.dark .mat-mdc-select-value {
  color: var(--bl-grey-300);
  font-size: 14px;
}
.dark .mat-mdc-option {
  background-color: var(--bl-grey-800);
  color: var(--bl-grey-300);
  border-color: var(--bl-orange-600);
}

.dark .mat-mdc-option.mat-mdc-option-active:not(.mdc-list-item--disabled) {
  background-color: var(--bl-grey-700);
}

.dark .mat-mdc-option .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: var(--bl-orange-500) !important;
}

.dark .mat-mdc-option .mdc-list-item__primary-text {
  color: var(--bl-grey-100);
}

.dark .mat-mdc-option:hover {
  background-color: var(--bl-grey-700);
}

/* Light mode styling attestant table */

.mat-mdc-option .mdc-list-item__primary-text {
  color: var(--bl-grey-800);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: var(--bl-orange-500) !important;
}
